.white{
  background: var(--white);
}

.ice{
  background: var(--beige);
}

.beige{
  background: var(--beige);
}