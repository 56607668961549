.button {
  position: relative;
  z-index: 1;
  cursor: pointer;
  opacity: 1;
  transition: .2s ease-in-out opacity;
}

.inside {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  padding: var(--spacing-12) var(--spacing-24);
  border-width: 2px;
  border-style: solid;
  transition: .2s ease-in-out;
  transition-property: background-color, color, border-color, opacity, padding, transform;
  border-radius: var(--radius-xl);
  font: var(--font-h3-mobile-default);
  font-family: var(--font-wix-text);
  font-weight: 800;
  white-space: nowrap;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* LOADER */

.loader {
  position: absolute;
  z-index: 1;
  left: 10px;
  opacity: 0;
  transition: 0.3s linear 0.3s;
  transition-property: opacity;
}

.isLoading .inside {
  padding-left: calc(var(--spacing-24) + var(--spacing-4) + var(--spacing-16));
}

.isLoading .loader {
  animation: 0.3s linear 0.3s fade-in forwards;
}

.icon, .loader {
  --size-icon: 24px;
  height: var(--size-icon);
  width: var(--size-icon);
}

.icon {
  margin-right: var(--spacing-12);
}

.inside.right {
  flex-direction: row-reverse;
}

.inside.right .icon {
  margin-right: 0;
  margin-left: var(--spacing-12);
}

.inside.center {
  justify-content: center;
}
.inside.center .icon {
  margin: 0;
}

/* COLORS */

.primary {
  background-color: var(--yellow);
  color: var(--black);
  border-color: var(--yellow);
}
.primary::before, .primary::after {
  border-color: var(--yellow);
}
.primary:not(:disabled):hover {
  border-color: var(--black);
  background-color: var(--black);
  color: var(--white);
}

.beige {
  background-color: var(--beige);
  color: var(--black);
  border-color: var(--beige);
}
.beige::before, .beige::after {
  border-color: var(--beige);
}
.beige:not(:disabled):hover {
  border-color: var(--white);
  background-color: var(--white);
  color: var(--black);
}

.secondary {
  background-color: var(--sauge);
  color: var(--white);
  border-color: var(--sauge);
}
.secondary::before, .secondary::after {
  border-color: var(--white);
}
.secondary:not(:disabled):hover {
  background-color: var(--b-green);
  color: var(--white);
}

.dark {
  background-color: var(--black);
  color: var(--sauge);
  border-color: var(--black);
}
.dark::before, .dark::after {
  border-color: var(--dark);
}
.dark:not(:disabled):hover {
  background-color: var(--b-green);
  color: var(--white);
}

/* Outline */
.outline .primary, .outline .primary::before, .outline .primary::after {
  background-color: transparent;
  border-color: var(--yellow);
  color: var(--black);
}
.outline .primary:not(:disabled):hover{
  border-color: var(--black);
  color: var(--black);
  background-color: transparent !important;
}

.outline .beige, .outline .beige::before, .outline .beige::after {
  background-color: transparent;
  border-color: var(--beige);
  color: var(--beige);
}
.outline .beige:not(:disabled):hover{
  border-color: var(--white);
  color: var(--white);
  background-color: transparent !important;
}

.outline .secondary, .outline .secondary::before, .outline .secondary::after {
  background-color: transparent;
  border-color: var(--sauge);
  color: var(--sauge);
}
.outline .secondary:not(:disabled):hover{
  border-color: var(--b-green);
  color: var(--b-green);
  background-color: transparent;
}

.outline .dark, .outline .dark::before, .outline .dark::after {
  background-color: transparent;
  border-color: var(--black);
  color: var(--black);
}

.outline .dark:not(:disabled):hover {
  background-color: transparent;
  border-color: var(--sauge);
  color: var(--black);
}

.outline .secondary .icon{
  color: var(--sauge);
}

/* ANIMATIONS */

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 0.7; }
}

@media (max-width: 1024px) {
  .button {
    --size-button: 2.5rem;
  }
  .button .inside {
    font: var(--font-p1-desktop-default);
    font-family: var(--font-wix-text);
    font-weight: 800;
  }
  .button .icon, .button .loader {
    --size-icon: 1.5rem;
  }
}