.editor p {
  font: var(--font-p1-desktop-default);
  color: inherit;
}

.editor a {
  text-decoration: underline;
}

.editor h1,
.editor h1 > span {
  font: var(--font-h1-desktop-default);
  color: inherit;
  
}

.editor h2,
.editor h2 > span {
  font: var(--font-h2-desktop-default);
  color: inherit;
  
}

.editor h3,
.editor h3 > span {
  font: var(--font-h3-mobile-default);
  color: inherit;
}

.editor h1 > strong {
  font: var(--font-h1-desktop-default);
  font-weight:900;
  color: inherit;
  
}

.editor h2 > strong {
  font: var(--font-h2-desktop-default);
  font-weight:900;
  color: inherit;
  
}

.editor h3 > strong {
  font: var(--font-h3-mobile-default);
  font-weight:900;
  color: inherit;
}

.editor ul{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
}

.editor ul li {
  position: relative; 
  padding-left: var(--spacing-16);
}

.editor ul li::before {
  --size-bullet: 8px;
  content: ""; 
  position: absolute;
  top: 50%;
  left: 0;
  width: var(--size-bullet);
  height: var(--size-bullet);
  background-color: var(--sauge); 
  transform: translateY(-50%);
  border-radius: 50%;
}

.editor ol {
  padding-left: 20px;
}

.editor ol li {
  list-style-type: decimal;
}

.editor strong {
  font-weight: 800 !important;
}

.editor em {
  font-style: italic;
}

@media (width <= 768px) {
  .editor p {
    font: var(--font-p1-mobile-default);
  }
  
  .editor h1,
  .editor h1 > span,
  .editor h1 > strong {
    font: var(--font-h1-mobile-default);
  }


  .editor h2,
  .editor h2 > span,
  .editor h2 > strong {
    font: var(--font-h2-mobile-default);  
  }
  
  .editor h3,
  .editor h3 > span,
  .editor h3 > strong {
    font: var(--font-h3-mobile-default);
  }
}
