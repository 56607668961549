.header {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  background: var(--beige);
  border-bottom: 1px solid var(--gray);
  color: var(--black);
}

.sticky{
  position: sticky;
}

.phone{
  display: flex;
  align-items: center;
  color: var(--black);
  font: var(--font-p2-desktop-default);
  font-weight: 800;
  flex: 1 0 auto;
}

.separator{
  height: 20px;
  width: 1px;
  background-color: var(--black);
}

.customButton {
  background-color: var(--yellow);
  border-radius: var(--radius-xl);
  padding: var(--spacing-8) var(--spacing-16);
  font: var(--font-p1-desktop-default);
  font-weight: 800;
  transition: 0.2s ease-in-out;
  transition-property: background-color color;
  white-space: nowrap;
}

.customButton:hover {
  background-color: var(--black);
  color: var(--white);
}

@media (width <= 768px) {
  .header {
    height: var(--mobile-header-height);
    border-bottom: 1px solid var(--gray);
  }
}

.inner_header {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 var(--spacing-40);
}

@media (width <= 768px) {
  .inner_header {
    padding: 0 var(--spacing-16);
  }
}

.logoLink{
  display: flex;
  align-items: center;
  margin-right: var(--spacing-24);
  height: 100%;
  width: 200px;
}

.burgerLogo{
  height: 30px;
  margin: 0;
  width: auto;
  max-width: 100%;
}

.logoContainer {
  position: relative;
  height: 100%;
  width: 100%;
  padding: var(--spacing-4) 0;
}

.logoContainer img {
  object-fit: contain;
}

.staticLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: var(--spacing-24);
}

.residence {
  background-color: var(--black);
  color: var(--white);
  padding: var(--spacing-8) var(--spacing-16);
  text-align: center;
}

.infoItems {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-40);
  gap: var(--spacing-24);
}

.languageSelectorMobile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-8);
}

.languageMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width: 48px;
  border: 1px solid var(--black);
  border-radius: var(--radius-s);
  color: var(--black);
  transition: all 0.25s;
  cursor: pointer;
}

.languageMobile:hover,
.languageMobile.active {
  background: var(--sauge);
  color: var(--white);
  font-weight: 800;
}

.languageMobile:active {
  background: var(--beige);
}