.item {
  position: relative;
}

.item > * {
  position: relative;
  font: var(--font-p2-desktop-default);
  color: var(--black);
  transition: all 0.5s;
  font-weight: 800;
}

.item:hover > a {
  color: var(--black);
}

.item > a:active {
  color: var(--black);
}

.navMobileContact {
  display: flex;
}

.active > a{
  color: var(--black);
  font-weight: 800;
}

/* 1200 */
@media screen and (max-width: 75rem) {
  .item {
    margin-left: 0.875rem;
  }
}

/* 1024 */
@media screen and (max-width: 64rem) {
  .item {
    font-size: 1rem;
    line-height: 1.188rem;
    letter-spacing: 0.12rem;
    margin-top: var(--spacing-24);
  }
}
