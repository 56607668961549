.quote {
  position: relative;
  padding: var(--spacing-80) var(--spacing-16) var(--spacing-80) var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-24);
  color: var(--white);
}

.quoteTitle {
  font: var(--font-h3-desktop-default);
  font-weight: 800;
}

.quoteText {
  font: var(--font-p2-desktop-default);
}

@media (max-width: 1024px) {
  .quote{
    padding: var(--spacing-40) var(--spacing-24);
  }
  .quote:after {
    --size-top: 4rem;
    --size-right: 2.5rem;
  }
}


@media (max-width: 768px) {
  .quote{
    padding: var(--spacing-40) var(--spacing-16);
  }
}